@font-face {
    font-family: Open Sans;
    src: url("./fonts/OpenSans-Regular.woff");
    font-weight:400;
}
@font-face {
    font-family: Open Sans;
    src: url("./fonts/OpenSans-Semibold.woff");
    font-weight:600;
}
@font-face {
    font-family: Open Sans;
    src: url("./fonts/OpenSans-Bold.woff");
    font-weight:700;
}