#text-search-control {
    position: relative;
    display: inline-block;
}
.searching_gif {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 11px;
    right: 38px;
    display: none;
}
#text-search-clear {
    position: absolute;
    top: 0px;
    right: 5px;
    display: none;
    padding: 14px;
    cursor: pointer;
}
#text-search-problem {
    display: none;
    height: 20px;
    width: 20px;
    position: absolute;
    right: 42px;
    top: 10px;
    cursor: pointer;
    @include no-user-select;
}
#text-search {
    -moz-background-clip: border;
    -moz-background-inline-policy: continuous;
    -moz-background-origin: padding;
    background: url("#{$path-to-img}text-search.svg") no-repeat 6px 7px $color-light-background;
    background-size: 25px 25px;
    border: $border-dark;
    color: #46461F;   /* Remove this color if a placeholder is used.  Instead, use the placeholder classes */
    font-size: 18px;
    padding: 6px 28px 8px 35px;
    margin-right: 7px;
    width: 275px;
    z-index: 10;
    outline: none;
    vertical-align: 19px;
    font-family: inherit;

    &.searching {
        background: url("#{$path-to-img}searching.gif") no-repeat 10px 9px $color-light-background;
        background-size: 18px;
    }
    &::-ms-clear {
        display: none;
    }
}
.search-examples {
    background: $color-light-background;
    display: none;
    position: fixed;
    z-index: 1000;
    overflow: hidden;
    border: $border-dark;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    color: $color-main-font;
    padding: 5px 0;
    font-size: 1.15em;

    h2 {
        font-size: 2em;
        margin-bottom: 6px;
        color: $color-dark-font;
    }
    h3 {
        font-size: 1.5em;
        margin-bottom: 3px;
        color: #1e388d;
    }
    .example {
        padding: 5px 10px 5px 27px;
    }
    div {
        display: block;
        line-height: 1.5;
        min-height: 0;
        padding: 2px 0.4em;
        text-decoration: none;
        &.details {
            margin-bottom: 4px
        }
    }
    ul {
        overflow: auto;
    }
}
/*autocomplete results*/
.search-results {
    position: absolute;
    background: $color-light-background;
    font-size: 1.15em;
    z-index: 1000;
    border: $border-dark;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    padding: 5px 0;
}
.suggestion-item {
    text-decoration: none;
    padding: 3px 7px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}
.search-result-options {
    position: absolute;
    color: $color-main-font;
    background-color: $color-light-background;
    border: 1px solid #ccc;
    border-left-color: #F6F6F6;
    border-radius: 0 5px 5px 0;
    z-index: 2000;
    li {
        border: solid transparent;
        border-width: 2px 0;

        &:first-child {
            border-top-right-radius: 5px;
        }
        &:last-child {
            border-bottom-right-radius: 5px;
        }
        &.inactive.selected {
            color: #ccc;
        }
    }
}
.search-results ul {
    overflow: auto;
}

.suggestion-item-label {
    @include text-overflow-ellipsis;
}
.search-examples .selected, .search-results .selected {
    background: #F6F6F6;
    border-color: transparent;
    color: #246BD4;
    cursor: default;
}
.provider-title {
    margin-top: 7px;
    padding-bottom: 5px;
    padding-left: 9px;
    font-weight: bold;
    color: #7A7A75;
    border-top: $border-lighter;
    padding-top: 10px;

    &:first-child {
        border-top: none;
        padding-top: 0;
    }
    div {
        display: inline-block;
        padding-right: 5px;
    }
    img {
        vertical-align: text-bottom;
        float: right;
        padding-right: 10px;
        @include size(154px, 18px);
    }
}

.search-result-label {
    width: 90%;
    display: inline-block;
    vertical-align: middle;

    img {
        padding-right: 6px;
        @include size(13px);
        vertical-align: -1px;
    }
}
img.search-result-ellipsis {
    height: 5px;
    width: 24px;
    padding-right: 0;
    vertical-align: 3px;
}
.text-highlight {
    color: #246BD4;
}
.sub-menu-indicator {
    display: inline-block;
    width: 0.54em;
    padding-right: 6px;
    float: right;
    margin-top: 0.25em;
    opacity: 0.3;
    height: 0.95em;

    &.active {
        opacity: 1;
    }
    &.rotate {
        opacity: 1;
        transform: rotate(90deg);
    }
}

.search-result-option {
    &.inactive {
        color: #ccc;
    }
    /*----  Search: fullscreen ---- */
    &.sub-menu-dropdown {
        position: inherit;
        width: calc(100% - 29px);
        background: transparent;
        border: none;
        padding-left: 29px;
        padding-top: 10px;
        list-style: none;
    }
}