.navigation-bar {
    height: 40px;
    border-bottom: $border-light;
    &> ul > li {
        height: 24px;
        width: 24px;
        @include background-norepeat-center;
        background-size: 24px 24px;
        border-radius: $border-radius-circle;
        cursor: pointer;
        display: inline-block;
        font-size: 24px;
    }
}
#layers-actions-right {
    display: none;
}
#layers-actions-left {
    @include flex-row-centered;
    @include size(100%);
    li {
        flex: 1 auto;
        display: flex;
        justify-content: center;
        margin: 0;
        border-radius: 0;
        border-right: 0;
        text-align: center;
        @include size(100%);
        &:hover {
            box-shadow: inset 0 0 1px rgba(0,0,0, 0.4), rgba(255,255,255,0.1) 0 1px 0;
        }
        img {
            width: 24px;
            height: 24px;
            vertical-align: middle;
        }
        &:after {
            background-color: $color-action-icons;
        }
    }
}
/*
 * --- Layers tab navigation ---
 */
#layers_edit:after {
    content: url("#{$path-to-actions-img}edit.svg");
}
#layers_edit.active::after {
    content: url("#{$path-to-actions-img}done-edit.svg");
}
#layers_add:after {
    content: url("#{$path-to-actions-img}add-layers.svg");
}
