// Generic
// ==========================================================================
$box-shadow-main: 0 2px 4px rgba(0,0,0,0.2),0 -1px 0 rgba(0,0,0,0.02);

// Borders
// ==========================================================================
$border-radius-default: 4px;
$border-radius-circle: 23px;

$border-dark: 1px solid #c1c1c1;
$border-light: 1px solid #ddd;
$border-lighter: 1px solid #eee;

// Image paths
// ==========================================================================
$path-to-img: '/images/';
$path-to-actions-img: '#{$path-to-img}actions/';
$path-to-panels-img: '#{$path-to-img}panels/';
$path-to-toolbar-img: '#{$path-to-img}toolbar/';

// Colors
// ==========================================================================

$color-primary: #f78e1e;

$color-dark-background: #eee;
$color-light-background: #fff;

$color-main-font: #333333;
$color-dark-font: #000000;
$color-light-font: #c1c1c1;
$color-link-font: #246bd4;

$color-border-on-hover: #F78F1E;

// Icon colors
// ==========================================================================
$color-toolbar-icons: #f78e1e;
$color-toolbar-icons-inactive: #cccccc;

$color-action-icons: #f78e1e;
$color-action-icons-inactive: #cbcbcb;