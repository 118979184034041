#basemaps-list li, .status-list li {
    padding: 10px 15px;
    border-bottom: $border-lighter;
    width: calc(100% - 30px);
}
#basemaps-list li {
    &.selected, &:hover{
        background: #F6F6F6;
        color: #246BD4;
    }
}
.gm-style > div,
.gm-style > iframe,
.gm-style > iframe > * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}
