.ui-front {
    z-index: 10001 !important;
}
.ui-dialog {
    width: calc(100% - 2px) !important;
    z-index: 10002 !important;
    border-radius: 0;
    .ui-dialog-titlebar {
        padding: 8px 15px;
        font-size: 1.2em;
        font-weight: 600;
    }
    .ui-dialog-buttonpane {
        padding: 0 !important;
        border-top: none;
        .ui-dialog-buttonset {
            float: none;
            @include flex-row-centered;
            padding: 0;

            button {
                flex: 1 auto;
                margin: 0 !important;
                border-radius: 0 !important;

                &:not(:first-child) {
                    border-left: none;
                }
            }
        }
    }
}

.ui-button-text-only .ui-button-text {
    padding: .6em 1em;
}
