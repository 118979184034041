/*
 * --- Selection Mode cursor style ---
 */
.leaflet-container.non-default-cursor-enabled.leaflet-grab.leaflet-interactive{
    cursor:copy !important;
    .leaflet-interactive{
            cursor:copy !important;
    }
}
    
