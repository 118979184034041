.leaflet-tooltip-right {
  margin-left: 16px;
  margin-top: -24px;
}

.leaflet-tooltip-right:before {
  top: 50%;
  left: 0;
  position: absolute;
  pointer-events: none;
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  border-left: 6px solid transparent;
  border-right: 26px solid;
  transform:rotate(-40deg);
  content: "";
  margin-left: -24px;
  margin-top: 12px;
  border-right-color: #fff;
}

.leaflet-tooltip-left:before {
  top: 50%;
  right: 0;
  position: absolute;
  pointer-events: none;
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  border-left: 6px solid transparent;
  border-right: 26px solid;
  transform:rotate(220deg);
  content: "";
  margin-right: -24px;
  margin-top: 12px;
  border-right-color: #fff;
}

.leaflet-tooltip-left {
  margin-left: -16px;
  margin-top: -24px;
}