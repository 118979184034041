
@font-face {
    font-family: Open Sans;
    src: url("fonts/OpenSans-Regular.woff");
    font-weight:400;
}
@font-face {
    font-family: Open Sans;
    src: url("fonts/OpenSans-Semibold.woff");
    font-weight:600;
}
@font-face {
    font-family: Open Sans;
    src: url("fonts/OpenSans-Bold.woff");
    font-weight:700;
}


html, body {
    height:100%;
}
header nav, footer {
    display: none;
}
body {
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 13px;
    color: #333333;
    overflow: auto;
    background-color: #eee;
}
pre {
    font-family: inherit;
    white-space: pre-wrap;
}

tr {
    height: 25px;
}

textarea, input.text, select {
    font-family: inherit;
    font-size: 12px;
    width: 100%;
    color: #555555;
    padding: 3px;
}

textarea, input.text, select  {
    border: 1px solid #CCCCCC;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
    border-radius: 4px;
}

option {
    padding-left: 3px;
}

.main-wrapper {
    float: left;
}

.left-content {
    width: 400px;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    position:absolute;
}

.right-content {
    height: 100%;
    position: absolute;
    left: 400px;
    margin-top: 25px;
}

.print-control {
    margin-top: 25px;
    margin-left: 10px;
    margin-right: 10px;
}

.centered {
    margin-left:auto;
    margin-right:auto;
}
.ui-widget {
    font-family: inherit;
}
.ui-button, .ui-widget-content .ui-state-default {
    color: #246BD4;
    font-weight: normal;
    background-image: linear-gradient(to bottom,#fff,#f4f4f4);
    background-repeat: repeat-x;
    border: 1px solid #ccc;
    border-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1) rgba(0,0,0,0.25);
    border-bottom-color: #b3b3b3;
    border-radius: 4px;
    box-shadow: inset 0 -35px 0 rgba(255,255,255,0.2),0 2px 2px rgba(0,0,0,0.05);
    font-size: 1em;
}
.ui-button.primary-btn, .ui-widget-content .ui-state-default.primary-btn {
    font-weight: 600;
}
.ui-button:not(:disabled):hover {
    background-image: linear-gradient(to bottom,#fff,#f9f9f9);
    border-color: #CCCCCC;
    color: #246BD4;
}

#print_map_canvas {
    border: 2px solid #999999;
}

.page-size {
    border-color: 1px solid #999999;
    background-color: #ffffff;
}

.button-container {
    display: none;
}

.map-section {
    height: 100%;
}

#print-page-header{
    margin-top: 10px;
    position: relative;
    height: 0.35in;
}

#print-page-footer{
    padding-top: 10px;
    position: relative;
}

.text-area-row textarea {
    resize: vertical;
}

#print-preview-rows td {
    padding-bottom: 25px;
}
.internalsMap-large {
    height: 100%;
    border-right: 1px solid #999;
}
.internalsMap-buttons{
    display: none;
}
.viewContainerLabel {
    font-size: 1.2em;
    position: absolute;
    top: 10px;
    left: 50%;
    z-index: 20;
    background-color: #ffffff;
    padding: 1.5px 8px;
    border: 2px solid #AAA7A7;
    border-radius: 3px;
}

/*
 * --- Mouse position ---
 */
.leaflet-control-mouseposition {
    padding: 0 0.4em 0;
    width: auto !important;
}
/******* location *********/
.left {
    left: 0;
    position: absolute;
    display: inline-block;
}

.right {
    right: 0;
    position: absolute;
    display: inline-block;

}
.center {
    position: absolute;
    display: inline-block;
}
/******* text-align *********/
.text-left {
    text-align: left;
}

.text-right {
    text-align: right;

}
.text-center {
    text-align: center;
}


/************************************/
    /*** Print Media style ***/
/************************************/
@media print {

    header nav, footer {
        display: none;
    }

    @page {
        margin-bottom: -2in;
        margin-right: 0;
        margin-left: 0;
        margin-top: 0.25in;
        size: auto;
    }
    body {
        background-color: white;
    }

    .main-wrapper {
        width: auto !important;
    }

    .page-size{
        border: none !important;
    }

    .left-content {
        display: none;
    }

    .right-content {
        width: auto;
        height: auto;
        left: 0;
    }

    /******** leaflet controls ******/
    .leaflet-control-zoomslider {
        display: none;
        z-index: -1000;
    }

    .leaflet-bar a{
        display: none;
        z-index: -1000;
    }

    .leaflet-control-layers-toggle {
        display: none;
        z-index: -1000;
    }

    /******** hide Google attribution ******/
    .gmnoprint, .gm-style-cc {
        display: none;
    }

}
