.credentials-field {
    margin-bottom: 12px;
}
#credentials-component label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
}

#credentials-component input {
    width: 93%;
}
