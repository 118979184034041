.feature-plugins-header {
    background: $color-light-background url("#{$path-to-img}expanded.svg") no-repeat 10px 50%;
    background-size: 16px 16px;
    border-bottom: 1px solid #eee;
    padding: 13px 13px 13px 33px;
    font-size: 1.1em;
    font-weight: 600;
    line-height: 14.4px;
    width: calc(100% - 46px);
    cursor: pointer;

    &.collapsed {
        background-image: url("#{$path-to-img}collapsed.svg");
        border-bottom: none;
    }
}
.viewContainer {
    display: none;
    float: left;
    height: 100%;
    background-color: $color-light-background;
    position: relative;
    z-index: 5;
    border-right: 1px solid #999;;
    box-sizing: border-box;
}
.viewContainerLabel {
    font-size: 1.2em;
    position: absolute;
    top: 10px;
    left: 50%;
    z-index: 1000;
    background-color: $color-light-background;
    padding: 4px 8px;
    border: 2px solid #AAA7A7;
    border-radius: 3px;
}
.smallViewContainer {
    height: 200px;
    position: relative;
}
.street-view-small-container {
    height: 240px;
    margin-bottom: 5px;
    box-shadow: $box-shadow-main;
}
.street-view-large, .internalsMap-large {
    height: 100%;
}
.street-view-small {
    position: relative;
    height: calc(100% - 40px);
    width: 100%;
}
.overMapButton {
    background-color: #f8f8f9;
    @include background-norepeat-center;
    background-size: 18px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 1px 5px rgba(152, 151, 151, 0.65);
    cursor: pointer;
    height: 28px;
    margin: 10px;
    position: absolute;
    top: 0;
    right: 0;
    width: 28px;
    z-index: 1000;

    &:not(.inactive):not(.no-hover):hover {
        box-shadow: inset 0 0 1px rgba(0,0,0, 0.4), rgba(255,255,255,0.1) 0 1px 0;
    }
}
.internalsMap-small {
    height: 100%;
    width: 100%;
}
.feature-plugins-details-button {
    font-size: 1.1em;
    font-weight: 600;
    height: 35px;
    padding: 3px 14px;
    text-align: left;
    cursor: pointer;
    border-bottom: $border-light;
    > span {
        vertical-align: middle;
        display: inline-block;
    }
}
.street-view-expandButton, .internalsMap-expandButton {
    background-image: url("#{$path-to-panels-img}expand_panel.svg");
    background-size: 18px;
    /*top: 38px;*/
}
.shrinkButton {
    background-image: url("#{$path-to-panels-img}shrink_panel.svg");
}
#street-view-hideMarkersButton {
    background-image: url("#{$path-to-img}delete-location.svg");
}
#street-view-showMarkersButton {
    background-image: url("#{$path-to-img}current-location.svg");
}
#street-view-hideMarkersButton, #street-view-showMarkersButton {
    top: 80px;
}
.expandMoreButton, .shrinkHalfButton {
    top: 40px;
}
.expandMoreButton {
    background-image: url("#{$path-to-panels-img}expand_right.svg");
    display: inline-block;
    float: right;
}
.shrinkHalfButton {
    background-image: url("#{$path-to-panels-img}shrink_left.svg");
    display: none;
}
#internals-div {
    position: relative;
    box-shadow: $box-shadow-main;
    margin-bottom: 5px;

    li.foreground_tab {
        background-color: #ddd;
        border-bottom: $border-light;
    }
    .leaflet-control-zoomslider-wrap.leaflet-bar-part {
        /*removes the zoom slider from the internals small container*/
        display: none;
    }
}
.streetview-label {
    background: #fff;
    padding: 6px;
    border-radius: 6px;
    overflow: visible !important;
    box-shadow: 0 1px 3px rgba(0,0,0,0.4);
}
.streetview-label:before {
 top: 50%;
  left: 0;
  position: absolute;
  pointer-events: none;
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  border-left: 6px solid transparent;
  border-right: 26px solid;
  transform:rotate(-38deg);
  content: "";
  margin-left: -24px;
  margin-top: 12px;
  border-right-color: #fff;
}