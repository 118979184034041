#feature-editor .panel-header {
    padding: 5px;
    background-image: none;
}
#feature-editor {
    .master-layer-icon {
        right: 15px;
        top: 40%;
        margin-top: -6px;
    }
}

.lock-editor {
    position: absolute;
    background: url('#{$path-to-actions-img}pin.svg') no-repeat center transparent;
    background-size: 20px;
    left: 5px;
    bottom: calc(50% - 10px);
    margin-top: -6px;
    height: 20px;
    padding: 0 10px;
    width: 20px;
    cursor: pointer;
    border-radius: 20px;

    &.active {
        background-image: url('#{$path-to-actions-img}unpin.svg');
    }
}

.feature-edit-container {
    padding: 10px;

    > table {
        padding: 0 5px;
    }
    textarea,
    input,
    select {
        font-family: inherit;
        font-size: 12px;
        color: #555555;
        display: inline-block;
        vertical-align: middle;
    }
    textarea,
    input:not(.feature-edit-input-small):not(.checkbox):not(.ui-checkbox) {
        width: 90%;
    }
    select {
        /*Since padding does not effect the select width like it does for input and textarea elements*/
        width: calc(90% + 14px);
    }
    textarea {
        resize: vertical;
    }
}
.feature-edit-actions {
    margin: 14px -10px 0;
    padding: 11px 0 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.19);
    .message-container .alert-message {
        margin: 10px 10px 0;
    }
}
.popup-feature-edit-actions {
    margin: 14px -23px 0;
    padding-top: 11px;
}
.popup-editor-title {
    padding-left: 30px;
}
.ui-dialog-title .master-layer-icon {
    background: url('#{$path-to-img}master-layer.svg') no-repeat center transparent;
    background-size: 16px;
    @include size(16px);
    position: relative;
    right: 0;
    display: inline-block;
    margin-left: 10px;
}
.num-field-input {
    white-space: nowrap;
}
.feature-edit-input-small {
    width: 120px;
    margin-right: 5px;
}
.feature-edit-checkbox {
    padding: 5px;
    width: 15px;
}
.required-field-notification {
    padding-left: 3px;
    vertical-align: 2px;
}
.disabled-input {
    background-color: $color-dark-background;
    position: relative;
}
.field-name-display {
    padding-right: 0 !important;
    min-width: 80px;
}

.mapObjectLabel {
    background-color: $color-dark-background;
    border-radius: $border-radius-default;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
    padding: 10px;
    text-align: center;
    border: 1px solid #ccc;
}

.mapObject {
    margin: 10px 0;
}
.mapObjectValidationLabel {
    font-size: 0.9em;
}
.set-map-object {
    cursor: pointer;
    div {
        float: right;
        background: url('#{$path-to-actions-img}location-on-grey.svg') no-repeat 98% center;
        background-color: $color-action-icons;
        width: 34px;
        height: 34px;
        margin-top: -8px;
    }
}
.validationHighlight {
    border: 1px solid #df6f56 !important;
}
.inlineValidation {
    color: #b23b39;
    line-height: 20px;
}
.invalid-tab-content {
    color: #b23b39;
    &:after {
        content: '!';
        color: #b23b39;
        padding-left: 5px;
        font-size: 11px;
        vertical-align: top;
    }
}

.focused-select-menu {
    white-space: nowrap;
    display: inline-block;

    > li {
        display: inline-block;
        border: 1px solid #ccc;
        padding: 7px 10px;
        background-color: #f6f6f6;
        cursor: pointer;
        min-width: 20px;
        text-align: center;
        height: 14px;
        vertical-align: middle;

        &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
        &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        &:not(:first-child) {
            border-left: none;
        }
        &.selected {
            background-color: white;
            color: $color-primary;
            font-weight: bold;
            box-shadow: 0px 0px 8px -4px $color-primary inset;
        }
    }
}

.feature-edit-container .image-input input[type='file'] {
    display: none;
    @include size(0 !important, 0);
}

/* overriding jquiry-ui */
.field-edit-btn {
    margin-right: 4px;
    color: $color-main-font !important;
    background-color: #f6f6f6 !important;
    font-weight: 600 !important;
}

.thumb-container {
    display: none;
    vertical-align: middle;
    border-radius: 3px;
    border: $border-dark;
    height: 24px;
    margin: 2px 0;
}
.thumb {
    @include size(24px, calc(100% - 2px));
    padding: 1px;
    border-right: $border-dark;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.thumb-file-size {
    display: inline-block;
    vertical-align: top;
    margin: 0 3px;
    font-size: 10px;
    line-height: 24px;
}
.thumb-clear {
    display: inline-block;
    @include size(18px, 100%);
    @include background-with-image('#{$path-to-img}x.svg');
    background-size: 8px 8px;
    border-left: $border-dark;
}
.photo_upload_options_dialog {
    padding: 5px 0 0 !important;

    > li {
        padding: 6px 10px;
        white-space: nowrap;

        &:hover {
            background: #f6f6f6 50% 50% repeat-x;
            cursor: pointer;
        }
        > img {
            @include size(25px);
            margin: 0 7px;
            vertical-align: -7px;
        }
    }
}

.custom-enum-list {
    &.ui-menu {
        z-index: 10;
        font-weight: normal;
        padding-bottom: 10px;
        position: absolute;
        width: 100px;

        .ui-menu-item {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &.ui-menu-icons .ui-menu-item-wrapper {
            padding: 3px 1em 6px 0.7em;
        }
    }
}
.ui-menu-item {
    .ui-menu-item-wrapper {
        border: 1px solid transparent;
    }
    .ui-state-active {
        border-color: #eee;
        background: #f6f6f6;
        color: #555;
        margin: 0;
        &.edit-btn {
            font-weight: 600;
        }
        .ui-icon {
            background-image: url(../../lib/jquery/images/ui-icons_444444_256x240.png);
        }
    }
    &.edit-btn {
        font-weight: 600;
    }
    .edit-btn {
        font-weight: 600;
    }
}
.custom-dropdown-button {
    overflow: hidden;
    white-space: nowrap;
    .ui-icon-triangle-1-s {
        margin-left: -21px;
    }
    .ui-input:disabled {
        background-color: #fff !important;
    }
}

.group-selector-button {
    .ui-icon-triangle-1-s {
        margin-left: -31px;
    }
    .ui-input:disabled {
        background-color: #fff !important;
    }
}
