.usage-monitor-dialog {    
    legend {
        font-weight: bold;
        margin-bottom: 10px;
    }
    
    table {
        margin-bottom: 10px;
        
        tr {
            td {
                padding: 3px;
            }
        }
    }
}

.usage-monitor-dialog {
    .tab-pane { overflow-y: scroll !important; }
}

.usage-monitor-launcher {
    background: #bcbcbc;
    font-weight: bold;
    color: #fff;
    display: block;
    text-align: center;
    cursor: pointer;
    
    &.active {
        background: #f78e1e;
    }
}