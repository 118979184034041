.error-alert-container {
    position: fixed;
    bottom: 0;
    padding-bottom: 15px;
    overflow: auto;
    @include size(100%, calc(100% - 15px));
    z-index: 1000;
    pointer-events: none;

    > div {
        pointer-events: all;
    }

    > .overlay-container {
        max-height: 100%;
        overflow: auto;
    }
}
