.help-pane {
    height: 100%;
    overflow: auto;
    padding: 8px;

    > img {
        display: block;
    }
}
.versionInfo {
    color: $color-light-font;
    font-size: 19px;
    margin-left: 5px;
    margin-top: 32px;
    padding-bottom: 40px;
}

.help-box {
    margin-top:10px;
    margin-left:5px;
}

.help-large-text {
    color: $color-dark-font;
    font-size: 36px;
    margin-right: 10px;
}

.help-medium-text {
    color: $color-light-font;
    font-size: 19px;
    margin-top: 32px;
    padding-bottom: 40px;
}

.help-small-text {
    color : $color-main-font;
    font-size: 13px;
}