#directions-panel {
    padding: 7px 7px 11px 7px;
    input.text {
        width: calc(100% - 44px);
        float: right;
    }
    .label {
        font-size: 1.2em;
    }
}
.directions-from-container {
    margin-bottom: 34px;
}
.directions-from-container, .directions-to-container {
    @include flex-row-centered;
    justify-content: space-between;
    margin-top: 0.5em;
}
#directions-current {
    @include background-with-image("#{$path-to-img}location.svg");
    @include size(20px);
    margin-right: 4px;
    vertical-align: -5px;
    display: inline-block;

    img {
        @include size(20px);
    }
}
#directions-go {
    margin: .5em .4em .5em 1em;
    min-width: 60px;
}
.directions-panel-left {
    width: calc(100% - 40px);
}
.directions-panel-right {
    position: absolute;
    right: 20px;
    top: calc(50% - 15px);
}
.reverse-directions-btn {
    border: 0;
    background: none;
    cursor: pointer;
}
.reverse-directions-icon {
    @include background-with-image("#{$path-to-actions-img}reverse-direction.svg");
    background-color: $color-action-icons;
    @include size(24px);
    padding: 5px;
}