#header-content {
    height: 100%;
    div {
        &.left {
            height: 65px;
            float: left;
            padding: 12px 0 0 10px;
        }
        &.right {
            height: 100%;
            float: right;
            text-align: right;
        }
    }
}