.leaflet-editing-icon:hover {
    cursor:crosshair;
}

.edit-marker-selected {
    background: rgba(128, 0, 0, 0.1);
    border: 4px dashed rgba(128, 0, 0, 0.6);
    border-radius: $border-radius-default;

    &:hover {
        cursor:crosshair;
    }
}

.leaflet-last-marker {
    border: 4px solid rgba(128, 0, 0, 0.6);
    margin-left: -8px !important;
    margin-top: -8px !important;
}