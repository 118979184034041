.createFeature-msg {
    padding: 1em;
}
.newFeature {
    background-color: #fbfbfb;
    border: 1px solid #E3E3E3;
    padding: 15px;
    line-height: 1.5;
    margin: 0 0 5px 0;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.12),0 -1px 0 rgba(0,0,0,0.02);
    &:hover {
        background: #F6F6F6;
        color: #246BD4;
    }
}