@import '~leaflet/dist/leaflet.css';
@import '~jquery-ui-bundle/jquery-ui.css';
@import '~leaflet-contextmenu/dist/leaflet.contextmenu.css';
@import '../../lib/jquery/jquery.contextMenu.css';
@import '../../lib/leaflet/plugins/L.Control.Zoomslider.css';
@import '../../lib/jquery/dataTables/DataTables/css/jquery.dataTables.css';
@import '../../lib/jquery/dataTables/ColVis/dataTables.colVis.css';
@import '_uiDialog';

.leaflet-contextmenu a.leaflet-contextmenu-item:hover {
    text-decoration: none !important;
}
.leaflet-touch .leaflet-control-zoomslider a:hover {
    width: 26px;
    text-decoration: none !important;
}

.leaflet-touch .leaflet-control-zoom a:hover {
    width: 30px;
    text-decoration: none !important;
}

.map-ios13 {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
}

body.ios13 .ui-dialog-content {
    -webkit-overflow-scrolling: touch;
}
