.panel-icon {
    @include background-norepeat-center;
    cursor: pointer;
    border-radius: $border-radius-circle;
    height: 24px;
    width: 24px;
    padding: 5px;
    margin: 0 6px;

    &:not(.inactive):not(.no-hover):hover {
        box-shadow: inset 0 0 1px rgba(0,0,0, 0.4), rgba(255,255,255,0.1) 0 1px 0;
    }
}