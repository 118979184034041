& {
    font-size: 13px;
    min-width: 0;
    background-color: $color-light-background;
}
/*---- Forms ---- */
input, select, textarea {
    font-family: inherit;
}
textarea, input.text, select  {
    border: 1px solid #CCCCCC;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
    border-radius: 4px;
}
input.text {
    height: 36px;
    padding: 0 7px;
}
textarea {
    padding: 5px 7px;
}
select {
    display: inline-block;
    height: 36px;
    padding: 2px 2px 2px 7px;
    vertical-align: 1.6px;
}
option {
    padding-left: 3px;
}

/*-------Buttons-------*/
.overlay-buttons button, .ui-button {
    color: #246BD4;
    font-weight: normal;
    background: $color-light-background;
    background-image: linear-gradient(to bottom,#fff,#f4f4f4);
    background-repeat: repeat-x;
    border: 1px solid rgba(0,0,0,0.1);
    border-bottom-color: #b3b3b3;
    box-shadow: inset 0 -35px 0 rgba(255,255,255,0.2),0 2px 2px rgba(0,0,0,0.05);
    font: inherit;

    &:not(:disabled):hover {
        background-image: linear-gradient(to bottom,#fff,#f9f9f9);
        border-color: #CCCCCC;
        color: #246BD4;
    }
}

.primary-btn {
    font-weight: 600;
    border-color: #cccccc;
}
.checkboxField > input {
    margin-left: 8px;
    margin-right: 4px;
    vertical-align: -2px;
}
.button, .ui-button {
    padding: 12px
}
.wrapper{
    position: relative;
    z-index: 1;
    @include size(100%);
    overflow: auto;
}
.clearit{
    clear: both;
}
.bottom-separator {
   border-bottom: $border-light;
}