.top .feature-info {
    @include flex-row-centered;
}
.top .feature-info div.center{
    width: calc(100% - 80px);
}
.top, .panel-header {
    border-bottom: 1px solid #ccc;
    box-shadow: 0 3px 3px rgba(220, 219, 219, 0.51);
}
.button-back {
    background: url("#{$path-to-img}left-arrow.svg") no-repeat center;
    width: 40px;
    height: 40px;
    border-radius: $border-radius-circle;
}
.button-back:hover{
    box-shadow: inset 0 0 1px rgba(0,0,0, 0.4), rgba(255,255,255,0.1) 0 1px 0;
}
.phone-layout-details-container {
    width:100%;
    position:absolute;
    top:60px;
    bottom:0px;
    left:0;
    right:0;
    overflow: auto;
}

#myWorldApp[data-myw-layout=desktop] .navigation-bar #details-actions>li {
    margin-left: 6px;
}

#details-actions {
    border-bottom: 1px solid #ccc;
    display: flex;
    background-color: #fff;

    > li {
        display: flex;
        justify-content: center;
        height: 40px;
        @include background-norepeat-center;
        background-size: 24px 24px;
        cursor: pointer;
        padding: 12px 0;
        vertical-align: bottom;
        flex: 1 auto;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 24px 24px;
        &:not(.inactive):hover {
            box-shadow: inset 0 0 1px rgba(0,0,0, 0.4), rgba(255,255,255,0.1) 0 1px 0;
        }
        &:after {
            background-color: $color-action-icons;
        }
        &.inactive:after {
            background-color: $color-action-icons-inactive;
        }
    }
    #details-directions:after {
        content: url("#{$path-to-actions-img}directions.svg");
    }
    #details-streetview:after {
        content: url("#{$path-to-actions-img}streetview.svg");   
    }
    #details-editable:after {
        content: url("#{$path-to-actions-img}edit.svg");
    }
}
.zoom-all-details {
    flex: 1 auto;
    @include background-with-image("#{$path-to-actions-img}zoom.svg");
    background-size: 20px;
    border-radius: $border-radius-circle;
    @include size(40px);
    &:hover {
        box-shadow: inset 0 0 1px rgba(0,0,0, 0.4), rgba(255,255,255,0.1) 0 1px 0;
    }
}
.close-btn, .clear-results {
    background: url("#{$path-to-img}x.svg") no-repeat 50% transparent !important;
    border-radius: $border-radius-circle;
    background-size: 15px;
    @include size(40px);
    list-style: none;
    &:hover {
        box-shadow: inset 0 0 1px rgba(0,0,0, 0.4), rgba(255,255,255,0.1) 0 1px 0;
    }
}
.tbl-details tr.atts {
    td, th {
        padding: 3px;
    }
    &:first-child > td, &:first-child > th {
        padding-top: 8px;
    }
    &:last-child > td, &:last-child > th {
        padding-bottom: 10px;
    }
}