.leaflet-contextmenu {
    width: auto !important;
}
.context-menu-list {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
}
.context-menu-item {
    color: #222;
    font-size: 1.1em;
    border: solid transparent;
    border-width: 1px 0;
    padding: 0.2em 2.4em;

    &.context-menu-hover {
        background-color: #f4f4f4;
        color: $color-dark-font;
        border-color: #f0f0f0;
    }
    &.context-menu-separator {
        border-bottom: 1px solid #e6e6e6;
    }
    &.context-menu-disabled {
        background-color: #fff;
        color: #bbb;
    }
}
