table.dataTable thead th, table.dataTable thead td {
    border-bottom: $border-dark !important;
}
.dataTables_wrapper {
    position: absolute;
}
.dataTables_wrapper.no-footer .dataTables_scrollBody {
    border: $border-lighter !important;
}

//Fixes scroll bars disappearing in ie11
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

    .dataTables_wrapper.no-footer .dataTables_scrollBody {
        width: 100% !important;
    }

    .dataTables_wrapper.no-footer .dataTables_scrollHead {
        width: 100% !important;
    }

}


div.ColVis {
    margin-bottom: 0;
}
button.ColVis_Button {
    border: none;
    border-radius: $border-radius-circle;
    box-shadow: none;
    background-image: url("#{$path-to-actions-img}hide.svg");
    @include background-norepeat-center;
    background-color: $color-action-icons;
    @include size(32px);
    filter: none;
    margin-right: 25px;
    margin-top: 2px;

    &:hover {
        border: none;
        box-shadow: inset 0 0 1px rgba(0,0,0, 0.4), rgba(255,255,255,0.1) 0 1px 0;
        background-image: url("#{$path-to-actions-img}hide.svg");
        @include background-norepeat-center;
        filter: none;
    }
}
ul.ColVis_collection {
    bottom: 0;
    overflow: auto;

    li span {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 85%;
    }
    label > *  {
        vertical-align: text-top;
    }
}

table.dataTable thead {
    .sorting_asc, .sorting_desc, .sorting {
        cursor: pointer;
        *cursor: hand;
    }
    .sorting {
        background: url("js/../../../lib/jquery/dataTables/images/sort_both.png") no-repeat center right !important;
    }
    .sorting_asc {
        background: url("js/../../../lib/jquery/dataTables/images/sort_asc.png") no-repeat center right !important;
    }
    .sorting_desc {
        background: url("js/../../../lib/jquery/dataTables/images/sort_desc.png") no-repeat center right !important;
    }
    .sorting_asc_disabled {
        background: url("js/../../../lib/jquery/dataTables/images/sort_asc_disabled.png") no-repeat center right !important;
    }
    .sorting_desc_disabled {
        background: url("js/../../../lib/jquery/dataTables/images/sort_desc_disabled.png") no-repeat center right !important;
    }
}
