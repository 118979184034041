.tab-panel {
	.tab-title {
		cursor: pointer;
		display: inline-block;
		font-size: 1em;
		font-weight: 600;
		min-width: 55px;
		text-align: center;
		padding: 4px 5px;
		position: relative;
		bottom:-1px;

		&.selected {
			border-bottom: 1px solid #FFFFFF;
			border-top: 1px solid #c1c1c1;
			border-left: 1px solid #c1c1c1 !important;
			border-right: 1px solid #c1c1c1 !important;
		}
		&:first-child {
			border-left: none;
		}
	}

	.tab-pane {
		border: 1px solid #c1c1c1;
		padding: 5px;
		min-height: 150px;
		margin-bottom: 7px;
		max-height: 300px;
			overflow: hidden;
			display: none;
			&.selected {
				display: block;
			}
	}
}
