/*
 * --- Zoom display ---
 */
.leaflet-control-zoom-display {
    background-color: $color-light-background;
    font: bold 12px/20px Tahoma, Verdana, sans-serif;
    height: 26px;
    text-align: center;
    width: 26px;
    line-height: 26px;
}

/*
 * --- Mouse position ---
 */
.leaflet-control-mouseposition {
    padding: 0 0.4em 0;
    width: auto !important;
}

/*
 * --- Magnifying glass ---
 */
.leaflet-control-magnifying-glass {
    background-image: url('#{$path-to-img}magnifying-glass-icon.png');
}

/*
 * ---Basemap control ---
 */
.leaflet-control-layers-base label {
    font-family: 'Open Sans', Arial, sans-serif;
    padding: 3px 16px;
    margin: 0 -10px 0 -6px;
    border-bottom: $border-lighter;

    &:first-child {
        margin-top: -6px;
        border-radius: 5px 5px 0 0;
    }
    &:last-child {
        margin-bottom: -6px;
        border-radius: 0 0 5px 5px;
    }
    .selected,
    &:hover {
        background: #f6f6f6;
        color: #246bd4;
    }
}
