.ui-form {
    table {
        width: 100%;
    }

    td {
        padding: 5px 0;
    }

    .left-section, .right-section {
        width: 50%;
        display: inline-block;
        vertical-align: top;
    }

    .ui-label {
        padding-right: 10px;
    }

    .ui-input, .ui-select {
        margin-right: 10px;

        &.small {
            width: 50px;
            min-width: 50px;
        }

        &.medium {
            width: 100px;
            min-width: 100px;
        }

        &.large {
            width: 200px;
            min-width: 200px;
        }

        &.block {
            width: calc(100% - 4px);
            min-width: calc(100% - 4px);
        }

        &:disabled {
            background-color: #eee;
        }
    }

    .ui-button {
        display: inline-block;
    }

    .ui-form-sub-title {
        border-bottom: 1px solid #ccc;
        margin: 30px 0 10px 0;
        padding-bottom: 5px;
        font-weight: 600;
    }
}

input[type="radio"].text{
    height: auto !important;
}
