.leaflet-control-layers {
    display: none;/*to hide the basemap control--*/
}
.leaflet-control-zoomslider, .leaflet-control-zoom {
    display: none;
}
#map_canvas>.leaflet-control-container>.leaflet-top {
    top: 59px;
}
.leaflet-grab.leaflet-interactive{
    cursor: pointer;
}