#reference-field-clear {
    position: absolute;
    padding: 10px 3px;
    cursor: pointer;
    left: 90%;
    top: 0px;
    margin: auto;
}

#related-field-container{
    position: relative;
    width:100%;
    height:27px;
    margin: auto;
}