body {
    @include no-user-select;
}

& {
    height: 100%;
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 12px;
    color: $color-main-font;
    background-color: $color-dark-background;

    a {
        outline: none;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
        img {
            border: 0;
        }
    }

    p {
        margin-bottom: 1em;
    }
    textarea,
    input.text,
    select {
        border: 1px solid #cccccc;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
        border-radius: $border-radius-default;
    }
    input.text {
        height: 25px;
        padding: 0 7px;
    }
    textarea {
        padding: 5px 7px;
    }
    select {
        display: inline-block;
        height: 27px;
        padding: 2px 2px 2px 7px;
        vertical-align: 1.6px;
    }
    option {
        padding-left: 3px;
    }
    .hidden {
        display: none;
    }
    .right {
        float: right;
    }
    .left {
        float: left;
    }
    .text-center {
        text-align: center;
    }
    .pointer {
        cursor: pointer;
    }
    .relative {
        position: relative;
    }
    #left-content {
        background-color: $color-light-background;
        height: 100%;
    }
    .ui-layout-west,
    .ui-layout-east {
        border: $border-dark;
    }
    .ui-layout-south {
        -webkit-user-select: none;
        -webkit-touch-callout: none;
    }
    .textClickHighlight {
        background: none repeat scroll 0 0 $color-dark-background;
        padding: 3px 7px;
    }
    .toggleImage {
        padding: 0 5px;
        @include size(16px);
    }
    .content-centered {
        text-align: center;
        vertical-align: middle;
    }
    #right-content {
        height: 100.8%;
        border: $border-dark;
        position: relative;
    }
    #map_canvas {
        height: 100%;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
        *:focus {
            outline: none !important;
        } // To stop unwanted focus on ctrl click
    }
    .remove {
        width: 19px;
    }
    .remove a {
        display: none;
    }
    .trfirst {
        border-top: $border-light;
    }
    .clearit {
        clear: both;
    }
    .first {
        margin-top: 0;
    }
    .last {
        margin-bottom: 0 !important;
    }
    .noStyleList {
        list-style: none;
    }
    .rounded3 {
        border-radius: 3px;
    }
    .rounded5 {
        border-radius: 5px;
    }
    .rounded10 {
        border-radius: 10px;
    }
    .rounded20 {
        border-radius: 20px;
    }
    .watermark {
        color: #aaaaaa;
    }
    .padded5 {
        padding: 5px 0;
    }
    .bottom-separator:not(:last-child) {
        border-bottom: $border-light;
    }
    .top-separator {
        border-top: $border-light;
    }
    .verticalScroll {
        overflow-y: auto;
    }
    .noselect {
        @include no-user-select;
    }
    .arrow {
        border-width: 11px;
    }
    .arrow,
    .arrow:after {
        position: absolute;
        display: block;
        @include size(0);
        border-color: transparent;
        border-style: solid;
    }
    .arrow:after {
        content: '';
        border-width: 10px;
    }

    :after,
    :before {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .inactive {
        cursor: auto;
    }
    .bold-font {
        font-weight: 600;
    }
    .ui-layout-resizer {
        -webkit-touch-callout: none;
        @include no-user-select;
    }
    .gm-style > iframe {
        display: none;
    }
}
